<template>
    <div>
        <template
            v-for="(media_file, index) in row.item.media"
        >
            <div>
                <a v-if="media_file.type"
                   :key="index"
                   @click.prevent="$root.openDocument(media_file.id, 'media')"
                   :href="$root.getDocumentUrl(media_file.id, 'media')"
                >{{ media_file.name.split(/[\\/]/).slice(-1)[0] }}</a>
                <a v-else
                   :key="index"
                   @click.prevent="$root.openDocument(media_file.name, 'incoming')"
                   :href="$root.getDocumentUrl(media_file.name, 'incoming')"
                >{{ media_file.name }}</a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>